import React from "react";
import styled from "styled-components";
import Logo from "../images/SSMA_logo_new.svg";
import { Link } from "gatsby"

const StyledWrapper = styled.div`
  .external-links {
    background-color: ${(props) => props.theme["borderBottomColor"]};
    text-align: center;
  }

  .header-external-links {
    margin-top: ${(props) => props.theme["pixel-20"]};
    font-size: 12px;
    color: ${(props) => props.theme["externalLinkColor"]};
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom:${(props) => props.theme["pixel-20"]};
    display:flex;
    align-items:center;
    justify-content:center;
  }
  .transform-sm {
    text-transform: unset;
  }
  .header-external-links > a{
    display:flex;
    align-items:center;
  }
  .border-right {
    border-right: 1px solid ${(props) => props.theme["primaryColor"]} !important;
    height:30px;
  }

  .upreach {
    color: ${(props) => props.theme["navbarColor"]};
    font-size: 12px;
    font-weight: ${(props) => props.theme["footerLinkBold"]};
    padding-top:10px;
  }

  .grid-upper-container {
    display: grid !important;
    grid-template-columns: ${(props) => props.theme["percent-25"]} auto auto auto auto auto;
  }

  .grid-lower-container {
    display: grid !important;
    grid-template-columns: ${(props) => props.theme["percent-25"]} auto;
    background-color: ${(props) => props.theme["primaryColor"]};
  }

  .internal-links {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-transform: uppercase;
    font-size: ${(props) => props.theme["headerLinkSize"]};
    font-weight: ${(props) => props.theme["headingBold"]};
  }

  .internal-links-a-tag.donate {
    color: ${(props) => props.theme["borderBottomColor"]};
  }

  .internal-links-a-tag {
    color: ${(props) => props.theme["borderBottomColor"]};
    position: relative;
    top: 5px;
    width: fit-content;
    margin-left: auto;
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.5s;
  }

  .internal-links-a-tag:hover {
    border-bottom: 2px solid #F2B542;
    text-decoration: none;
  }

  .active {
    border-bottom: 2px solid #F2B542;
  }

  .header-mobile-desktop-visibility {
    @media (max-width: 1091px) {
      display: none;
    }
  }

  .sticky-nav {
    background-color: white;
    width: 100%;
    z-index: 9;
  }

  .upreach-transforming {
    position: relative;
    top:15px;
  }

  .main__logo {
    max-width: 200px;
  }

  .white-background{
    background-color:#FFFFFF;
  }
`;

const Header = () => {
  const [fixed, setFixed] = React.useState(80);
  const [position, setPosition] = React.useState(null);
  const [showMore,setShowMore] = React.useState(false);

  const pixelValue = fixed + "px";

  React.useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (window.pageYOffset > 100) {
        setFixed(0);
        setPosition("fixed");
      }
      if (window.pageYOffset < 100) {
        setFixed(80);
        setPosition("unset");
      }
    });
  }, []);

  return (
    <StyledWrapper>
      <div className="header-mobile-desktop-visibility">
        <div className="grid-upper-container pb-3 external-links">
          <div className="item1">
            <div className="upreach-transforming">
            <p className="header-external-links upreach upreach-program my-1">Student Social Mobility Awards</p>
            </div>
          </div>
          <div className="item2 white-background">
            <Link target="_blank" to="https://upreach.org.uk">
              <p className="header-external-links border-right px-3">
                UPREACH
              </p>
            </Link>
          </div>
          <div className="item3 white-background">
            <Link target="_blank" to="https://aspire.upreach.org.uk/">
              <p className="header-external-links border-right px-3">
                ASPIRE
              </p>
            </Link>
          </div>
          <div className="item4 white-background">
            <Link target="_blank" to="http://socialmobilitynetwork.org.uk/">
              <p className="header-external-links border-right px-3">
                Social Mobility Network
              </p>
            </Link>
          </div>
          <div className="item5 white-background">
            <Link target="_blank" to="https://realrating.co.uk/">
              <p className="header-external-links border-right px-3">
              Contextual Recruitment
              </p>
            </Link>
          </div>
          <div className="item6 white-background">
            <Link target="_blank" to="https://getemployable.org/">
              <p className="header-external-links px-3 pt-lg-1">
                graduate employability framework
              </p>
            </Link>
          </div>
        </div>
        <div
          className="sticky-nav"
          style={{ top: pixelValue, position: position }}
        >
          <div className="grid-lower-container py-4">
            <div className="item1">
              <Link to="/">
                <img className="mx-auto mb-0 pl-2 d-flex main__logo" src={Logo} alt="" />
              </Link>
            </div>
            <div className="row d-flex justify-content-end internal-links pr-1 pr-md-5">
                  <Link activeClassName="active" partiallyActive={true} className="internal-links-a-tag" to="/about">
                  About
                </Link>
                <Link activeClassName="active" partiallyActive={true} className="internal-links-a-tag" to="/nominations">
                  Nominations
                </Link>
                <Link activeClassName="active" partiallyActive={true} className="internal-links-a-tag" to="/judging-panel">
                Judging Panel
                </Link>
                <Link activeClassName="active" partiallyActive={true} className="internal-links-a-tag" to="/sponsors">
                Sponsors
              </Link>
              <Link activeClassName="active" partiallyActive={true} className="internal-links-a-tag" to="/previous-winners">
               Award Winners
              </Link>
              <Link activeClassName="active" partiallyActive={true} className="internal-links-a-tag" to="/gallery">
               Gallery
              </Link>
              {/* <Link activeClassName="active" partiallyActive={true} className="internal-links-a-tag" to="/stories">
               Stories
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </StyledWrapper>
  );
};

export default Header;
