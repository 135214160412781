import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledWrapper = styled.div`
  .right-sidebar {
    right: ${props => props.theme["zero"]};
    overflow: scroll;
    @media (max-width: 450px) {
      top: 90px;
    }

    @media (min-width: 450px) and (max-width: 550px){
      top: 90px;
    }

    @media (min-width: 550px) and (max-width: 599px){
      top: 100px;
    }

    @media (min-width: 600px) and (max-width: 991px) {
      top: 110px;
    }
  }

  .fixed-height {
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .subtext {
    color: ${props => props.theme["secondaryColor"]};
    font-weight: bold;
    padding-left: 20px;
    text-transform: uppercase;
  }

  .toggle {
    width: 60px;
    padding: 15px;
  }
`

const SidebarRight = [
  {
    navLink: "Sixth-Form",
    color: "#5DB9DB",
    href: "https://aspire.upreach.org.uk",
  },
  {
    navLink: "Contextualised Recruitment",
    color: "#E43D5A",
    href: "https://realrating.co.uk",
  },
  {
    navLink: "Graduate Employability Framework",
    color: "#FF8600",
    href: "https://getemployable.org",
  },
  {
    navLink: "Social Mobility Network",
    color: "#304563",
    href: "http://socialmobilitynetwork.org.uk/",
  },
  {
    navLink: "STUDENT SOCIAL MOBILITY AWARDS",
    color: "#F2B842",
    href: "https://studentsocialmobilityawards.org.uk",
  },
]

const RightSidebar = ({
  setLeftSidebar,
  leftSidebar,
  setRightSidebar,
  rightSidebar,
}) => {
  return (
    <StyledWrapper>
      <nav className="container-fluid right-sidebar sidebar">
        <div className="row">
          {SidebarRight.map(links => (
            <div
              className="col-12 fixed-height"
              style={{ backgroundColor: `${links.color}` }}
            >
              <Link className="subtext" target="_blank" to={links.href}>
                {links.navLink}
              </Link>
            </div>
          ))}
        </div>
      </nav>
    </StyledWrapper>
  )
}

export default RightSidebar
